export const whenWillIGetPaid = {
  order: 3,
  name: "When will I get paid?",
  keywords: "cash money credit payout",
  subcategory: "Earn revenue",
  markdown: `# When will I get paid?

  Once you begin {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}earning revenue{% /inlineRouterLink %} on Tower Hunt, it's important to understand when you get paid. Read on to find out.

  {% callout type="tip" %}
  **Tip:** Wondering **where** you'll get paid? Make sure to read about {% inlineRouterLink articleId="manage-payout-accounts" %}payout accounts{% /inlineRouterLink %}.
  {% /callout %}

  ## Cash

  Cash earnings are paid out to your account on a **monthly** basis. Earnings are booked **daily**, and you can keep track of pending vs booked earnings in your {% inlineRouterLink articleId="track-your-earnings" %}Contributions overview{% /inlineRouterLink %}.

  ## Data credits

  Data credit earnings are recognized in real-time and displayed in your {% inlineAppIcon iconName="dollar" /%}{% inlineRouterLink articleId="key-actions-hub" %}**available balance**{% /inlineRouterLink %}. You can spend these as soon as you see them in your balance.`,
};
